import { AuctionType } from '@/api/degenerate-trait-auctions';
import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('@/views/HomeView.vue'),
        },

        // {
        //   path: "/v2/",
        //   name: "v2",
        //   component: () => import("@/views/BurnView.vue"),
        // },

        {
            path: '/auction/s/:auction/',
            name: 'auctionStandard',
            component: () => import('@/views/AuctionView.vue'),
            props: { auctionType: AuctionType.Standard },
        },

        {
            path: '/auction/b/:auction/',
            name: 'auctionBurn',
            component: () => import('@/views/AuctionView.vue'),
            props: { auctionType: AuctionType.Burn },
        },

        {
            path: '/auction/a/:auction/',
            name: 'auctionBurnAny',
            component: () => import('@/views/AuctionView.vue'),
            props: { auctionType: AuctionType.BurnAny },
        },

        {
            path: '/auctions/admin/',
            name: 'auctionAdmin',
            component: () => import('@/views/AuctionAdminView.vue'),
        },

        {
            path: '/mining/',
            name: 'mining',
            component: () => import('@/views/MiningView.vue'),
        },

        {
            path: '/attendance/',
            name: 'attendance',
            component: () => import('@/views/AttendanceView.vue'),
        },

        {
            path: '/hatchening/',
            name: 'hatchening',
            component: () => import('@/views/HatchView.vue'),
        },

        {
            path: '/explorer/',
            name: 'explorer',
            component: () => import('@/views/ExplorerView.vue'),
        },

        // {
        //   path: "/privacy/",
        //   name: "privacy",
        //   component: () => import("../views/PrivacyPolicy.vue"),
        // },

        // {
        //   path: "/authorize/discord/",
        //   name: "discord",
        //   component: () => import("../views/DiscordAuthView.vue")
        // },

        // {
        //   path: "/authorize/twitter/",
        //   name: "twitter",
        //   component: () => import("../views/TwitterAuthView.vue")
        // }
    ],
    scrollBehavior(to) {
        if (to.hash) return { el: to.hash, behavior: 'smooth' };
        return { top: 0 };
    },
});

export default router;
