import {
    Connection,
    PublicKey,
    Keypair,
    SystemProgram,
    SYSVAR_CLOCK_PUBKEY,
    SYSVAR_RENT_PUBKEY,
} from '@solana/web3.js';
import * as anchor from '@coral-xyz/anchor';
import { DEGEN_ADMIN, OPAL_ID, RPC_MAINNET } from '@/helpers/constants';
import { toLeBytes } from '@/helpers/utilities';
import {
    TOKEN_PROGRAM_ID,
    getAssociatedTokenAddressSync,
} from '@solana/spl-token';
import {
    PROGRAM_ID as STAR_PROGRAM_ID,
    getBookAddress,
} from './degenerate-star';
import NodeWallet from '@coral-xyz/anchor/dist/cjs/nodewallet';

export const PROGRAM_ID = new PublicKey(
    `eBay7KHGzjgy66Zhb8CFCcJtkDfymtcptF5LiD2dESZ`
);

const AUCTION_SEED = 'DEGENERATEAUCTION';
const BID_ITEM_SEED = 'DEGENERATEBIDITEM';
const BID_MAP_SEED = 'DEGENERATEBIDMAP';
const HOLDING_SEED = 'DEGENERATEHOLDING';

export enum AuctionType {
    Standard,
    Burn,
    BurnAny,
}

/** Auction account as stored by the program */
export interface Auction {
    address: PublicKey;
    authority: PublicKey;
    bidMap: PublicKey;
    rewardMint: PublicKey;
    rewardPoolSize: number;
    startTime: number;
    endTime: number;
    duration: number;
    claimWindow: number;
    active: boolean;
}

export interface AuctionWithBidMap extends Auction {
    bidMapData: number[];
    threshold: number;
}

export interface BurnAuction {
    address: PublicKey;
    authority: PublicKey;
    bidMap: PublicKey;
    rewardMint: PublicKey;
    rewardPoolSize: number;
    toBurnMint: PublicKey;
    toBurnSize: number;
    startTime: number;
    endTime: number;
    duration: number;
    claimWindow: number;
    active: boolean;
}

export interface BurnAuctionWithBidMap extends BurnAuction {
    bidMapData: number[];
    threshold: number;
}

export interface BurnAnyAuction {
    address: PublicKey;
    authority: PublicKey;
    bidMap: PublicKey;
    rewardMint: PublicKey;
    rewardPoolSize: number;
    toBurnCollection: PublicKey;
    toBurnSize: number;
    startTime: number;
    endTime: number;
    duration: number;
    claimWindow: number;
    active: boolean;
}

export interface BurnAnyAuctionWithBidMap extends BurnAnyAuction {
    bidMapData: number[];
    threshold: number;
}

export interface Holding {
    owner: PublicKey;
    auction: PublicKey;
    source: PublicKey;
    amount: number;
    claimed: boolean;
}

export interface BidMap {
    auction: PublicKey;
    data: number[];
}

export interface BidItem {
    address: PublicKey;
    owner: PublicKey;
    auction: PublicKey;
    time: number;
    amount: number;
    domain?: string;
}

export interface GetBidHistoryArgs {
    auction: PublicKey;
    owner?: PublicKey;
}

export interface ConfigureAuctionOpts {
    supply: number;
    duration: number;
    claimWindow: number;
}

export interface CreateOrModifyHoldingArgs {
    auction: PublicKey;
    owner: PublicKey;
    amount: number;
    type: AuctionType;
}

export const getProgram = () => {
    const provider = new anchor.AnchorProvider(
        new Connection(RPC_MAINNET),
        new NodeWallet(Keypair.generate()),
        anchor.AnchorProvider.defaultOptions()
    );
    return new anchor.Program(IDL, PROGRAM_ID, provider);
};

export const getRarityFromSupply = (supply: number) => {
    if (supply < 15) {
        return `Mythic`;
    } else if (supply < 40) {
        return `Super Rare`;
    } else if (supply < 80) {
        return `Rare`;
    } else if (supply < 340) {
        return `Uncommon`;
    } else return `Common`;
};

export const parseAuctionData = async (
    auction: PublicKey,
    data: any,
    type: AuctionType
) => {
    const program = getProgram();
    const buffer = Buffer.from(data, `base64`);
    const bidMapAddress = getBidMapFromAuction(auction);
    const bidMapData = await program.account.bidMap.fetch(bidMapAddress);
    if (type === AuctionType.Burn) {
        const auctionData = program.coder.accounts.decode(
            `burnAuction`,
            buffer
        );
        return {
            ...auctionData,
            address: auction,
            bump: undefined,
            bidMapData: bidMapData.data,
            threshold:
                bidMapData.data.length > 0 ? Math.min(...bidMapData.data) : 0,
            claimWindow: auctionData.claimWindow.toNumber(),
            duration: auctionData.duration.toNumber(),
            endTime: auctionData.endTime.toNumber(),
            rewardPoolSize: auctionData.rewardPoolSize.toNumber(),
            toBurnMint: auctionData.toBurnMint,
            toBurnSize: auctionData.toBurnSize.toNumber(),
            startTime: auctionData.startTime.toNumber(),
        } as BurnAuctionWithBidMap;
    } else if (type === AuctionType.BurnAny) {
        const auctionData = program.coder.accounts.decode(
            `burnAnyAuction`,
            buffer
        );
        return {
            ...auctionData,
            address: auction,
            bump: undefined,
            bidMapData: bidMapData.data,
            threshold:
                bidMapData.data.length > 0 ? Math.min(...bidMapData.data) : 0,
            claimWindow: auctionData.claimWindow.toNumber(),
            duration: auctionData.duration.toNumber(),
            endTime: auctionData.endTime.toNumber(),
            rewardPoolSize: auctionData.rewardPoolSize.toNumber(),
            toBurnCollection: auctionData.toBurnCollection,
            toBurnSize: auctionData.toBurnSize.toNumber(),
            startTime: auctionData.startTime.toNumber(),
        } as BurnAnyAuctionWithBidMap;
    } else {
        const auctionData = program.coder.accounts.decode(`auction`, buffer);
        return {
            ...auctionData,
            address: auction,
            bump: undefined,
            bidMapData: bidMapData.data,
            threshold:
                bidMapData.data.length > 0 ? Math.min(...bidMapData.data) : 0,
            claimWindow: auctionData.claimWindow.toNumber(),
            duration: auctionData.duration.toNumber(),
            endTime: auctionData.endTime.toNumber(),
            rewardPoolSize: auctionData.rewardPoolSize.toNumber(),
            startTime: auctionData.startTime.toNumber(),
        } as AuctionWithBidMap;
    }
};

export const getBidMapFromAuction = (auction: PublicKey) => {
    return PublicKey.findProgramAddressSync(
        [Buffer.from(BID_MAP_SEED), auction.toBuffer()],
        PROGRAM_ID
    )[0];
};

export const getHoldingFromAuction = (owner: PublicKey, auction: PublicKey) => {
    return PublicKey.findProgramAddressSync(
        [Buffer.from(HOLDING_SEED), auction.toBuffer(), owner.toBuffer()],
        PROGRAM_ID
    )[0];
};

export const getHoldingAccount = async (
    owner: PublicKey,
    auction: PublicKey
) => {
    const program = getProgram();
    const holdingAddress = getHoldingFromAuction(owner, auction);
    return await program.account.holding.fetch(holdingAddress);
};

export const getActiveAuctions = async (): Promise<Auction[]> => {
    const program = getProgram();
    const auctionData = await program.account.auction.all([
        {
            memcmp: {
                offset: 8 + 8 + 32 + 32 + 8 + 8 + 8 + 8 + 8,
                bytes: '1',
            },
        },
    ]);
    return auctionData.map((i) => {
        return {
            ...i.account,
            address: i.publicKey,
            bump: undefined,
            claimWindow: i.account.claimWindow.toNumber(),
            duration: i.account.duration.toNumber(),
            endTime: i.account.endTime.toNumber(),
            rewardPoolSize: i.account.rewardPoolSize.toNumber(),
            startTime: i.account.startTime.toNumber(),
        };
    });
};

export const getBidHistory = async ({
    auction,
    owner,
}: GetBidHistoryArgs): Promise<BidItem[]> => {
    const program = getProgram();
    let results: any;
    if (owner) {
        results = await program.account.bidItem.all([
            {
                memcmp: {
                    offset: 8,
                    bytes: owner.toBase58(),
                },
            },
            {
                memcmp: {
                    offset: 8 + 32,
                    bytes: auction.toBase58(),
                },
            },
        ]);
    } else {
        results = await program.account.bidItem.all([
            {
                memcmp: {
                    offset: 8 + 32,
                    bytes: auction.toBase58(),
                },
            },
        ]);
    }
    return results.map((i: any) => {
        return {
            address: i.publicKey,
            time: i.account.time.toNumber(),
            ...i.account,
        };
    });
};

export const getAuction = async (
    auction: PublicKey,
    type: AuctionType
): Promise<AuctionWithBidMap | BurnAuctionWithBidMap> => {
    const program = getProgram();
    const bidMapAddress = getBidMapFromAuction(auction);
    const bidMapData = await program.account.bidMap.fetch(bidMapAddress);
    if (type === AuctionType.Burn) {
        const auctionData = await program.account.burnAuction.fetch(auction);
        return {
            ...auctionData,
            address: auction,
            bump: undefined,
            bidMapData: bidMapData.data,
            threshold:
                bidMapData.data.length > 0 ? Math.min(...bidMapData.data) : 0,
            claimWindow: auctionData.claimWindow.toNumber(),
            duration: auctionData.duration.toNumber(),
            endTime: auctionData.endTime.toNumber(),
            rewardPoolSize: auctionData.rewardPoolSize.toNumber(),
            toBurnMint: auctionData.toBurnMint,
            toBurnSize: auctionData.toBurnSize.toNumber(),
            startTime: auctionData.startTime.toNumber(),
        } as BurnAuctionWithBidMap;
    } else if (type === AuctionType.BurnAny) {
        const auctionData = await program.account.burnAnyAuction.fetch(auction);
        return {
            ...auctionData,
            address: auction,
            bump: undefined,
            bidMapData: bidMapData.data,
            threshold:
                bidMapData.data.length > 0 ? Math.min(...bidMapData.data) : 0,
            claimWindow: auctionData.claimWindow.toNumber(),
            duration: auctionData.duration.toNumber(),
            endTime: auctionData.endTime.toNumber(),
            rewardPoolSize: auctionData.rewardPoolSize.toNumber(),
            toBurnCollection: auctionData.toBurnCollection,
            toBurnSize: auctionData.toBurnSize.toNumber(),
            startTime: auctionData.startTime.toNumber(),
        } as BurnAnyAuctionWithBidMap;
    } else {
        const auctionData = await program.account.auction.fetch(auction);
        return {
            ...auctionData,
            address: auction,
            bump: undefined,
            bidMapData: bidMapData.data,
            threshold:
                bidMapData.data.length > 0 ? Math.min(...bidMapData.data) : 0,
            claimWindow: auctionData.claimWindow.toNumber(),
            duration: auctionData.duration.toNumber(),
            endTime: auctionData.endTime.toNumber(),
            rewardPoolSize: auctionData.rewardPoolSize.toNumber(),
            startTime: auctionData.startTime.toNumber(),
        } as AuctionWithBidMap;
    }
};

export const getAuctionAddressFromMint = (mint: PublicKey) => {
    return PublicKey.findProgramAddressSync(
        [Buffer.from(AUCTION_SEED), DEGEN_ADMIN.toBuffer(), mint.toBuffer()],
        PROGRAM_ID
    )[0];
};

export const getCreateAuctionInstruction = async (mint: PublicKey) => {
    const program = getProgram();
    const [auctionAddr, auctionBump] = PublicKey.findProgramAddressSync(
        [Buffer.from(AUCTION_SEED), DEGEN_ADMIN.toBuffer(), mint.toBuffer()],
        PROGRAM_ID
    );
    const [bidMapAddr] = PublicKey.findProgramAddressSync(
        [Buffer.from(BID_MAP_SEED), auctionAddr.toBuffer()],
        PROGRAM_ID
    );
    const instruction = await program.methods
        .createAuction(auctionBump)
        .accounts({
            auction: auctionAddr,
            bidMap: bidMapAddr,
            authority: DEGEN_ADMIN,
            rewardMint: mint,
            rent: SYSVAR_RENT_PUBKEY,
            systemProgram: SystemProgram.programId,
        })
        .instruction();
    return {
        instruction,
        data: {
            auction: auctionAddr.toBase58(),
            bidMap: bidMapAddr.toBase58(),
            reward: mint.toBase58(),
        },
    };
};

export const getConfigureAuctionInstruction = async (
    auction: PublicKey,
    options: ConfigureAuctionOpts
) => {
    const program = getProgram();
    const rewardMint = await getRewardMintFromAuction(auction);
    console.log(rewardMint.toBase58());
    const instruction = await program.methods
        .configureAuction(
            new anchor.BN(options.supply),
            new anchor.BN(options.duration),
            new anchor.BN(options.claimWindow)
        )
        .accounts({
            auction,
            authority: DEGEN_ADMIN,
        })
        .instruction();
    return {
        instruction,
        data: null,
    };
};

export const getStartAuctionInstruction = async (auction: PublicKey) => {
    const program = getProgram();
    const rewardMint = await getRewardMintFromAuction(auction);
    const [auctionRewardAccount] = PublicKey.findProgramAddressSync(
        [Buffer.from(AUCTION_SEED), rewardMint.toBuffer(), auction.toBuffer()],
        PROGRAM_ID
    );
    const authorityRewardAccount = getAssociatedTokenAddressSync(
        rewardMint,
        DEGEN_ADMIN
    );
    return await program.methods
        .startAuction()
        .accounts({
            auction,
            auctionRewardAccount,
            authority: DEGEN_ADMIN,
            authorityRewardAccount,
            rewardMint,
            clock: SYSVAR_CLOCK_PUBKEY,
            rent: SYSVAR_RENT_PUBKEY,
            tokenProgram: TOKEN_PROGRAM_ID,
            systemProgram: SystemProgram.programId,
        })
        .instruction();
};

export const getSubmitBidInstruction = async ({
    auction,
    owner,
    amount,
    type,
}: CreateOrModifyHoldingArgs) => {
    const program = getProgram();
    const [holding] = PublicKey.findProgramAddressSync(
        [Buffer.from(HOLDING_SEED), auction.toBuffer(), owner.toBuffer()],
        PROGRAM_ID
    );
    const holdingAccount = await program.account.holding.fetchNullable(holding);
    console.log(holdingAccount);
    const [source] = getBookAddress({ owner, star: OPAL_ID });
    if (holdingAccount) {
        const [bidItemAddr] = anchor.web3.PublicKey.findProgramAddressSync(
            [
                Buffer.from(BID_ITEM_SEED),
                auction.toBuffer(),
                owner.toBuffer(),
                toLeBytes(holdingAccount.numOfBids),
            ],
            PROGRAM_ID
        );
        console.log(
            `Found Bid Item ${
                holdingAccount.numOfBids
            } address ${bidItemAddr.toBase58()}`
        );
        if (type === AuctionType.Burn) {
            return {
                instruction: await program.methods
                    .modifyBurnAuction(amount)
                    .accounts({
                        auction,
                        bidItem: bidItemAddr,
                        bidMap: getBidMapFromAuction(auction),
                        clock: SYSVAR_CLOCK_PUBKEY,
                        holding,
                        source,
                        owner,
                        opalProgram: OPAL_ID,
                        degenerateStarProgram: STAR_PROGRAM_ID,
                        systemProgram: SystemProgram.programId,
                    })
                    .instruction(),
                address: bidItemAddr,
            };
        } else if (type === AuctionType.BurnAny) {
            return {
                instruction: await program.methods
                    .modifyBurnAnyAuction(amount)
                    .accounts({
                        auction,
                        bidItem: bidItemAddr,
                        bidMap: getBidMapFromAuction(auction),
                        clock: SYSVAR_CLOCK_PUBKEY,
                        holding,
                        source,
                        owner,
                        opalProgram: OPAL_ID,
                        degenerateStarProgram: STAR_PROGRAM_ID,
                        systemProgram: SystemProgram.programId,
                    })
                    .instruction(),
                address: bidItemAddr,
            };
        } else {
            return {
                instruction: await program.methods
                    .modifyAuction(amount)
                    .accounts({
                        auction,
                        bidItem: bidItemAddr,
                        bidMap: getBidMapFromAuction(auction),
                        clock: SYSVAR_CLOCK_PUBKEY,
                        holding,
                        source,
                        owner,
                        opalProgram: OPAL_ID,
                        degenerateStarProgram: STAR_PROGRAM_ID,
                        systemProgram: SystemProgram.programId,
                    })
                    .instruction(),
                address: bidItemAddr,
            };
        }
    } else {
        const [bidItemAddr] = anchor.web3.PublicKey.findProgramAddressSync(
            [
                Buffer.from(BID_ITEM_SEED),
                auction.toBuffer(),
                owner.toBuffer(),
                toLeBytes(0),
            ],
            PROGRAM_ID
        );
        console.log(`Found Bid Item 0 address ${bidItemAddr.toBase58()}`);
        if (type === AuctionType.Burn) {
            return {
                instruction: await program.methods
                    .bidBurnAuction(amount)
                    .accounts({
                        auction,
                        bidItem: bidItemAddr,
                        bidMap: getBidMapFromAuction(auction),
                        clock: SYSVAR_CLOCK_PUBKEY,
                        holding,
                        source,
                        owner,
                        opalProgram: OPAL_ID,
                        degenerateStarProgram: STAR_PROGRAM_ID,
                        systemProgram: SystemProgram.programId,
                    })
                    .instruction(),
                address: bidItemAddr,
            };
        } else if (type === AuctionType.BurnAny) {
            return {
                instruction: await program.methods
                    .bidBurnAnyAuction(amount)
                    .accounts({
                        auction,
                        bidItem: bidItemAddr,
                        bidMap: getBidMapFromAuction(auction),
                        clock: SYSVAR_CLOCK_PUBKEY,
                        holding,
                        source,
                        owner,
                        opalProgram: OPAL_ID,
                        degenerateStarProgram: STAR_PROGRAM_ID,
                        systemProgram: SystemProgram.programId,
                    })
                    .instruction(),
                address: bidItemAddr,
            };
        } else {
            return {
                instruction: await program.methods
                    .bidAuction(amount)
                    .accounts({
                        auction,
                        bidItem: bidItemAddr,
                        bidMap: getBidMapFromAuction(auction),
                        clock: SYSVAR_CLOCK_PUBKEY,
                        holding,
                        source,
                        owner,
                        opalProgram: OPAL_ID,
                        degenerateStarProgram: STAR_PROGRAM_ID,
                        systemProgram: SystemProgram.programId,
                    })
                    .instruction(),
                address: bidItemAddr,
            };
        }
    }
};

export const getSettleInstruction = async ({
    auction,
    owner,
}: Omit<CreateOrModifyHoldingArgs, `amount`>) => {
    const program = getProgram();
    const rewardMint = await getRewardMintFromAuction(auction);
    const [auctionRewardAccount] = PublicKey.findProgramAddressSync(
        [Buffer.from(AUCTION_SEED), rewardMint.toBuffer(), auction.toBuffer()],
        PROGRAM_ID
    );
    const [holding] = PublicKey.findProgramAddressSync(
        [Buffer.from(HOLDING_SEED), auction.toBuffer(), owner.toBuffer()],
        PROGRAM_ID
    );
    const [source] = getBookAddress({ owner, star: OPAL_ID });
    const ownerRewardAccount = getAssociatedTokenAddressSync(rewardMint, owner);
    return await program.methods
        .settleAuction()
        .accounts({
            holding,
            auction,
            bidMap: getBidMapFromAuction(auction),
            owner,
            authority: DEGEN_ADMIN,
            source,
            rewardMint,
            auctionRewardAccount,
            ownerRewardAccount,
            clock: SYSVAR_CLOCK_PUBKEY,
            opalProgram: OPAL_ID,
            degenerateStarProgram: STAR_PROGRAM_ID,
            tokenProgram: TOKEN_PROGRAM_ID,
            systemProgram: SystemProgram.programId,
        })
        .instruction();
};

export const getCloseAuctionInstruction = async (auction: PublicKey) => {
    const program = getProgram();
    const rewardMint = await getRewardMintFromAuction(auction);
    const [auctionRewardAccount] = PublicKey.findProgramAddressSync(
        [Buffer.from(AUCTION_SEED), rewardMint.toBuffer(), auction.toBuffer()],
        PROGRAM_ID
    );
    const authorityRewardAccount = getAssociatedTokenAddressSync(
        rewardMint,
        DEGEN_ADMIN
    );
    return await program.methods
        .closeAuction()
        .accounts({
            auction,
            authority: DEGEN_ADMIN,
            bidMap: getBidMapFromAuction(auction),
            rewardMint,
            auctionRewardAccount,
            authorityRewardAccount,
            clock: SYSVAR_CLOCK_PUBKEY,
            rent: SYSVAR_RENT_PUBKEY,
            tokenProgram: TOKEN_PROGRAM_ID,
            systemProgram: SystemProgram.programId,
        })
        .instruction();
};

export const getRewardMintFromAuction = async (auction: PublicKey) => {
    const program = getProgram();
    const auctionAccount = await program.account.auction.fetch(auction);
    console.log(JSON.parse(JSON.stringify(auctionAccount)));
    return auctionAccount.rewardMint;
};

export type TraitAuctions = {
    version: '0.1.0';
    name: 'trait_auctions';
    instructions: [
        {
            name: 'createAuction';
            accounts: [
                {
                    name: 'auction';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'iteration';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'bidMap';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'authority';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'rewardMint';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'rent';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'systemProgram';
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [
                {
                    name: 'bump';
                    type: 'u8';
                },
            ];
        },
        {
            name: 'createBurnAuction';
            accounts: [
                {
                    name: 'auction';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'iteration';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'bidMap';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'authority';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'rewardMint';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'toBurnMint';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'rent';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'systemProgram';
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [
                {
                    name: 'bump';
                    type: 'u8';
                },
            ];
        },
        {
            name: 'createBurnAnyAuction';
            accounts: [
                {
                    name: 'auction';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'iteration';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'bidMap';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'authority';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'rewardMint';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'toBurnCollection';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'rent';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'systemProgram';
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [
                {
                    name: 'bump';
                    type: 'u8';
                },
            ];
        },
        {
            name: 'configureAuction';
            accounts: [
                {
                    name: 'auction';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'authority';
                    isMut: true;
                    isSigner: true;
                },
            ];
            args: [
                {
                    name: 'amount';
                    type: 'u64';
                },
                {
                    name: 'duration';
                    type: 'i64';
                },
                {
                    name: 'claimWindow';
                    type: 'i64';
                },
            ];
        },
        {
            name: 'configureBurnAuction';
            accounts: [
                {
                    name: 'auction';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'authority';
                    isMut: true;
                    isSigner: true;
                },
            ];
            args: [
                {
                    name: 'amount';
                    type: 'u64';
                },
                {
                    name: 'burnAmount';
                    type: 'u64';
                },
                {
                    name: 'duration';
                    type: 'i64';
                },
                {
                    name: 'claimWindow';
                    type: 'i64';
                },
            ];
        },
        {
            name: 'configureBurnAnyAuction';
            accounts: [
                {
                    name: 'auction';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'authority';
                    isMut: true;
                    isSigner: true;
                },
            ];
            args: [
                {
                    name: 'amount';
                    type: 'u64';
                },
                {
                    name: 'burnAmount';
                    type: 'u64';
                },
                {
                    name: 'duration';
                    type: 'i64';
                },
                {
                    name: 'claimWindow';
                    type: 'i64';
                },
            ];
        },
        {
            name: 'startAuction';
            accounts: [
                {
                    name: 'auction';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'authority';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'rewardMint';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'auctionRewardAccount';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'authorityRewardAccount';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'clock';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'rent';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'tokenProgram';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'systemProgram';
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [];
        },
        {
            name: 'startBurnAuction';
            accounts: [
                {
                    name: 'auction';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'authority';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'rewardMint';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'auctionRewardAccount';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'authorityRewardAccount';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'clock';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'rent';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'tokenProgram';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'systemProgram';
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [];
        },
        {
            name: 'startBurnAnyAuction';
            accounts: [
                {
                    name: 'auction';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'authority';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'rewardMint';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'auctionRewardAccount';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'authorityRewardAccount';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'clock';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'rent';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'tokenProgram';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'systemProgram';
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [];
        },
        {
            name: 'bidAuction';
            accounts: [
                {
                    name: 'holding';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'bidItem';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'auction';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'bidMap';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'owner';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'source';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'clock';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'opalProgram';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'degenerateStarProgram';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'systemProgram';
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [
                {
                    name: 'amount';
                    type: 'u32';
                },
            ];
        },
        {
            name: 'bidBurnAuction';
            accounts: [
                {
                    name: 'holding';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'bidItem';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'auction';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'bidMap';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'owner';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'source';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'clock';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'opalProgram';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'degenerateStarProgram';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'systemProgram';
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [
                {
                    name: 'amount';
                    type: 'u32';
                },
            ];
        },
        {
            name: 'bidBurnAnyAuction';
            accounts: [
                {
                    name: 'holding';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'bidItem';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'auction';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'bidMap';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'owner';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'source';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'clock';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'opalProgram';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'degenerateStarProgram';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'systemProgram';
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [
                {
                    name: 'amount';
                    type: 'u32';
                },
            ];
        },
        {
            name: 'modifyAuction';
            accounts: [
                {
                    name: 'holding';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'bidItem';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'auction';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'bidMap';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'owner';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'source';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'clock';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'opalProgram';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'degenerateStarProgram';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'systemProgram';
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [
                {
                    name: 'amount';
                    type: 'u32';
                },
            ];
        },
        {
            name: 'modifyBurnAuction';
            accounts: [
                {
                    name: 'holding';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'bidItem';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'auction';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'bidMap';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'owner';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'source';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'clock';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'opalProgram';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'degenerateStarProgram';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'systemProgram';
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [
                {
                    name: 'amount';
                    type: 'u32';
                },
            ];
        },
        {
            name: 'modifyBurnAnyAuction';
            accounts: [
                {
                    name: 'holding';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'bidItem';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'auction';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'bidMap';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'owner';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'source';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'clock';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'opalProgram';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'degenerateStarProgram';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'systemProgram';
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [
                {
                    name: 'amount';
                    type: 'u32';
                },
            ];
        },
        {
            name: 'settleAuction';
            accounts: [
                {
                    name: 'holding';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'auction';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'bidMap';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'owner';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'authority';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'source';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'rewardMint';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'auctionRewardAccount';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'ownerRewardAccount';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'clock';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'opalProgram';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'degenerateStarProgram';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'tokenProgram';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'systemProgram';
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [];
        },
        {
            name: 'settleBurnAuction';
            accounts: [
                {
                    name: 'holding';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'auction';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'bidMap';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'owner';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'authority';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'source';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'rewardMint';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'auctionRewardAccount';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'ownerRewardAccount';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'toBurnMint';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'toBurnAccount';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'clock';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'opalProgram';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'degenerateStarProgram';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'tokenProgram';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'systemProgram';
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [];
        },
        {
            name: 'settleBurnAnyAuction';
            accounts: [
                {
                    name: 'holding';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'auction';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'bidMap';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'owner';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'authority';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'source';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'rewardMint';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'auctionRewardAccount';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'ownerRewardAccount';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'toBurnMint';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'toBurnAccount';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'toBurnMetadata';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'toBurnCollection';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'clock';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'opalProgram';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'degenerateStarProgram';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'tokenProgram';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'systemProgram';
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [];
        },
        {
            name: 'closeAuction';
            accounts: [
                {
                    name: 'auction';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'bidMap';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'authority';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'rewardMint';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'auctionRewardAccount';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'authorityRewardAccount';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'tokenProgram';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'rent';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'clock';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'systemProgram';
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [];
        },
        {
            name: 'closeBurnAuction';
            accounts: [
                {
                    name: 'auction';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'bidMap';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'authority';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'rewardMint';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'auctionRewardAccount';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'authorityRewardAccount';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'toBurnMint';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'tokenProgram';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'rent';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'clock';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'systemProgram';
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [];
        },
        {
            name: 'closeBurnAnyAuction';
            accounts: [
                {
                    name: 'auction';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'bidMap';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'authority';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'rewardMint';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'auctionRewardAccount';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'authorityRewardAccount';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'toBurnCollection';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'tokenProgram';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'rent';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'clock';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'systemProgram';
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [];
        },
    ];
    accounts: [
        {
            name: 'auction';
            type: {
                kind: 'struct';
                fields: [
                    {
                        name: 'bump';
                        type: 'u8';
                    },
                    {
                        name: 'authority';
                        type: 'publicKey';
                    },
                    {
                        name: 'iteration';
                        type: 'publicKey';
                    },
                    {
                        name: 'bidMap';
                        type: 'publicKey';
                    },
                    {
                        name: 'rewardMint';
                        type: 'publicKey';
                    },
                    {
                        name: 'rewardPoolSize';
                        type: 'u64';
                    },
                    {
                        name: 'startTime';
                        type: 'i64';
                    },
                    {
                        name: 'endTime';
                        type: 'i64';
                    },
                    {
                        name: 'duration';
                        type: 'i64';
                    },
                    {
                        name: 'claimWindow';
                        type: 'i64';
                    },
                    {
                        name: 'numOfBidders';
                        type: 'u32';
                    },
                    {
                        name: 'active';
                        type: 'bool';
                    },
                ];
            };
        },
        {
            name: 'burnAuction';
            type: {
                kind: 'struct';
                fields: [
                    {
                        name: 'bump';
                        type: 'u8';
                    },
                    {
                        name: 'authority';
                        type: 'publicKey';
                    },
                    {
                        name: 'iteration';
                        type: 'publicKey';
                    },
                    {
                        name: 'bidMap';
                        type: 'publicKey';
                    },
                    {
                        name: 'rewardMint';
                        type: 'publicKey';
                    },
                    {
                        name: 'rewardPoolSize';
                        type: 'u64';
                    },
                    {
                        name: 'toBurnMint';
                        type: 'publicKey';
                    },
                    {
                        name: 'toBurnSize';
                        type: 'u64';
                    },
                    {
                        name: 'startTime';
                        type: 'i64';
                    },
                    {
                        name: 'endTime';
                        type: 'i64';
                    },
                    {
                        name: 'duration';
                        type: 'i64';
                    },
                    {
                        name: 'claimWindow';
                        type: 'i64';
                    },
                    {
                        name: 'numOfBidders';
                        type: 'u32';
                    },
                    {
                        name: 'active';
                        type: 'bool';
                    },
                ];
            };
        },
        {
            name: 'burnAnyAuction';
            type: {
                kind: 'struct';
                fields: [
                    {
                        name: 'bump';
                        type: 'u8';
                    },
                    {
                        name: 'authority';
                        type: 'publicKey';
                    },
                    {
                        name: 'iteration';
                        type: 'publicKey';
                    },
                    {
                        name: 'bidMap';
                        type: 'publicKey';
                    },
                    {
                        name: 'rewardMint';
                        type: 'publicKey';
                    },
                    {
                        name: 'rewardPoolSize';
                        type: 'u64';
                    },
                    {
                        name: 'toBurnCollection';
                        type: 'publicKey';
                    },
                    {
                        name: 'toBurnSize';
                        type: 'u64';
                    },
                    {
                        name: 'startTime';
                        type: 'i64';
                    },
                    {
                        name: 'endTime';
                        type: 'i64';
                    },
                    {
                        name: 'duration';
                        type: 'i64';
                    },
                    {
                        name: 'claimWindow';
                        type: 'i64';
                    },
                    {
                        name: 'numOfBidders';
                        type: 'u32';
                    },
                    {
                        name: 'active';
                        type: 'bool';
                    },
                ];
            };
        },
        {
            name: 'holding';
            type: {
                kind: 'struct';
                fields: [
                    {
                        name: 'owner';
                        type: 'publicKey';
                    },
                    {
                        name: 'auction';
                        type: 'publicKey';
                    },
                    {
                        name: 'source';
                        type: 'publicKey';
                    },
                    {
                        name: 'amount';
                        type: 'u32';
                    },
                    {
                        name: 'claimed';
                        type: 'bool';
                    },
                    {
                        name: 'numOfBids';
                        type: 'u8';
                    },
                    {
                        name: 'lastUpdated';
                        type: 'i64';
                    },
                ];
            };
        },
        {
            name: 'bidMap';
            type: {
                kind: 'struct';
                fields: [
                    {
                        name: 'auction';
                        type: 'publicKey';
                    },
                    {
                        name: 'data';
                        type: {
                            vec: 'u32';
                        };
                    },
                ];
            };
        },
        {
            name: 'bidItem';
            type: {
                kind: 'struct';
                fields: [
                    {
                        name: 'owner';
                        type: 'publicKey';
                    },
                    {
                        name: 'auction';
                        type: 'publicKey';
                    },
                    {
                        name: 'time';
                        type: 'i64';
                    },
                    {
                        name: 'amount';
                        type: 'u32';
                    },
                ];
            };
        },
    ];
    errors: [
        {
            code: 6000;
            name: 'InvalidProgram';
            msg: 'The program ID is incorrect';
        },
        {
            code: 6001;
            name: 'InvalidAuthority';
            msg: 'This account does not have authority to perform this instruction';
        },
        {
            code: 6002;
            name: 'AuctionAlreadyStarted';
            msg: 'This auction has already started';
        },
        {
            code: 6003;
            name: 'AuctionNotStarted';
            msg: 'This auction has not started';
        },
        {
            code: 6004;
            name: 'BidTooLow';
            msg: 'This bid is lower than the minimum required';
        },
        {
            code: 6005;
            name: 'InvalidToken';
            msg: 'You cannot attach this type of token';
        },
        {
            code: 6006;
            name: 'TokenNotOwned';
            msg: 'Requester does not own this token';
        },
        {
            code: 6007;
            name: 'UnrecognizedStar';
            msg: 'The Star account does not match this metadata';
        },
        {
            code: 6008;
            name: 'BidNotUnique';
            msg: 'This bid already exists in the bid map';
        },
        {
            code: 6009;
            name: 'NameTooLong';
            msg: 'The name string is too long';
        },
        {
            code: 6010;
            name: 'ImageTooLong';
            msg: 'The image string is too long';
        },
        {
            code: 6011;
            name: 'InvalidAuction';
            msg: 'This auction is not assigned to the given holding';
        },
        {
            code: 6012;
            name: 'AuctionNotFinished';
            msg: 'This auction has not finished yet';
        },
        {
            code: 6013;
            name: 'AuctionClaimsRemain';
            msg: "This auction's bid map is not empty";
        },
        {
            code: 6014;
            name: 'AuctionHasFinished';
            msg: 'This auction has finished';
        },
        {
            code: 6015;
            name: 'InvalidMint';
            msg: 'The token mint on this metadata account does not match';
        },
        {
            code: 6016;
            name: 'BidNotFound';
            msg: 'The bid was not found in the bid map';
        },
        {
            code: 6017;
            name: 'InvalidOwner';
            msg: 'The owner does not own this enrollment record';
        },
        {
            code: 6018;
            name: 'AlreadyClaimed';
            msg: 'This holding has already been fully claimed';
        },
        {
            code: 6019;
            name: 'BidMapError';
            msg: 'Something went wrong trying to add to the bid map';
        },
        {
            code: 6020;
            name: 'TooManyBids';
            msg: 'The bidder has bid too many times';
        },
        {
            code: 6021;
            name: 'RequiresTraitAndNumber';
            msg: 'If either trait or trait number is set, you must have both';
        },
    ];
};

export const IDL: TraitAuctions = {
    version: '0.1.0',
    name: 'trait_auctions',
    instructions: [
        {
            name: 'createAuction',
            accounts: [
                {
                    name: 'auction',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'iteration',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'bidMap',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'authority',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'rewardMint',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'rent',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [
                {
                    name: 'bump',
                    type: 'u8',
                },
            ],
        },
        {
            name: 'createBurnAuction',
            accounts: [
                {
                    name: 'auction',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'iteration',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'bidMap',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'authority',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'rewardMint',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'toBurnMint',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'rent',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [
                {
                    name: 'bump',
                    type: 'u8',
                },
            ],
        },
        {
            name: 'createBurnAnyAuction',
            accounts: [
                {
                    name: 'auction',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'iteration',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'bidMap',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'authority',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'rewardMint',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'toBurnCollection',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'rent',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [
                {
                    name: 'bump',
                    type: 'u8',
                },
            ],
        },
        {
            name: 'configureAuction',
            accounts: [
                {
                    name: 'auction',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'authority',
                    isMut: true,
                    isSigner: true,
                },
            ],
            args: [
                {
                    name: 'amount',
                    type: 'u64',
                },
                {
                    name: 'duration',
                    type: 'i64',
                },
                {
                    name: 'claimWindow',
                    type: 'i64',
                },
            ],
        },
        {
            name: 'configureBurnAuction',
            accounts: [
                {
                    name: 'auction',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'authority',
                    isMut: true,
                    isSigner: true,
                },
            ],
            args: [
                {
                    name: 'amount',
                    type: 'u64',
                },
                {
                    name: 'burnAmount',
                    type: 'u64',
                },
                {
                    name: 'duration',
                    type: 'i64',
                },
                {
                    name: 'claimWindow',
                    type: 'i64',
                },
            ],
        },
        {
            name: 'configureBurnAnyAuction',
            accounts: [
                {
                    name: 'auction',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'authority',
                    isMut: true,
                    isSigner: true,
                },
            ],
            args: [
                {
                    name: 'amount',
                    type: 'u64',
                },
                {
                    name: 'burnAmount',
                    type: 'u64',
                },
                {
                    name: 'duration',
                    type: 'i64',
                },
                {
                    name: 'claimWindow',
                    type: 'i64',
                },
            ],
        },
        {
            name: 'startAuction',
            accounts: [
                {
                    name: 'auction',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'authority',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'rewardMint',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'auctionRewardAccount',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'authorityRewardAccount',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'rent',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [],
        },
        {
            name: 'startBurnAuction',
            accounts: [
                {
                    name: 'auction',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'authority',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'rewardMint',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'auctionRewardAccount',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'authorityRewardAccount',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'rent',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [],
        },
        {
            name: 'startBurnAnyAuction',
            accounts: [
                {
                    name: 'auction',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'authority',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'rewardMint',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'auctionRewardAccount',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'authorityRewardAccount',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'rent',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [],
        },
        {
            name: 'bidAuction',
            accounts: [
                {
                    name: 'holding',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'bidItem',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'auction',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'bidMap',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'owner',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'source',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'opalProgram',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'degenerateStarProgram',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [
                {
                    name: 'amount',
                    type: 'u32',
                },
            ],
        },
        {
            name: 'bidBurnAuction',
            accounts: [
                {
                    name: 'holding',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'bidItem',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'auction',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'bidMap',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'owner',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'source',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'opalProgram',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'degenerateStarProgram',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [
                {
                    name: 'amount',
                    type: 'u32',
                },
            ],
        },
        {
            name: 'bidBurnAnyAuction',
            accounts: [
                {
                    name: 'holding',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'bidItem',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'auction',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'bidMap',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'owner',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'source',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'opalProgram',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'degenerateStarProgram',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [
                {
                    name: 'amount',
                    type: 'u32',
                },
            ],
        },
        {
            name: 'modifyAuction',
            accounts: [
                {
                    name: 'holding',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'bidItem',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'auction',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'bidMap',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'owner',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'source',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'opalProgram',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'degenerateStarProgram',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [
                {
                    name: 'amount',
                    type: 'u32',
                },
            ],
        },
        {
            name: 'modifyBurnAuction',
            accounts: [
                {
                    name: 'holding',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'bidItem',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'auction',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'bidMap',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'owner',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'source',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'opalProgram',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'degenerateStarProgram',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [
                {
                    name: 'amount',
                    type: 'u32',
                },
            ],
        },
        {
            name: 'modifyBurnAnyAuction',
            accounts: [
                {
                    name: 'holding',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'bidItem',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'auction',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'bidMap',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'owner',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'source',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'opalProgram',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'degenerateStarProgram',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [
                {
                    name: 'amount',
                    type: 'u32',
                },
            ],
        },
        {
            name: 'settleAuction',
            accounts: [
                {
                    name: 'holding',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'auction',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'bidMap',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'owner',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'authority',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'source',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'rewardMint',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'auctionRewardAccount',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'ownerRewardAccount',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'opalProgram',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'degenerateStarProgram',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [],
        },
        {
            name: 'settleBurnAuction',
            accounts: [
                {
                    name: 'holding',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'auction',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'bidMap',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'owner',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'authority',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'source',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'rewardMint',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'auctionRewardAccount',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'ownerRewardAccount',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'toBurnMint',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'toBurnAccount',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'opalProgram',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'degenerateStarProgram',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [],
        },
        {
            name: 'settleBurnAnyAuction',
            accounts: [
                {
                    name: 'holding',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'auction',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'bidMap',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'owner',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'authority',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'source',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'rewardMint',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'auctionRewardAccount',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'ownerRewardAccount',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'toBurnMint',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'toBurnAccount',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'toBurnMetadata',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'toBurnCollection',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'opalProgram',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'degenerateStarProgram',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [],
        },
        {
            name: 'closeAuction',
            accounts: [
                {
                    name: 'auction',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'bidMap',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'authority',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'rewardMint',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'auctionRewardAccount',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'authorityRewardAccount',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'rent',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [],
        },
        {
            name: 'closeBurnAuction',
            accounts: [
                {
                    name: 'auction',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'bidMap',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'authority',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'rewardMint',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'auctionRewardAccount',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'authorityRewardAccount',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'toBurnMint',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'rent',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [],
        },
        {
            name: 'closeBurnAnyAuction',
            accounts: [
                {
                    name: 'auction',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'bidMap',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'authority',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'rewardMint',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'auctionRewardAccount',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'authorityRewardAccount',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'toBurnCollection',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'rent',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [],
        },
    ],
    accounts: [
        {
            name: 'auction',
            type: {
                kind: 'struct',
                fields: [
                    {
                        name: 'bump',
                        type: 'u8',
                    },
                    {
                        name: 'authority',
                        type: 'publicKey',
                    },
                    {
                        name: 'iteration',
                        type: 'publicKey',
                    },
                    {
                        name: 'bidMap',
                        type: 'publicKey',
                    },
                    {
                        name: 'rewardMint',
                        type: 'publicKey',
                    },
                    {
                        name: 'rewardPoolSize',
                        type: 'u64',
                    },
                    {
                        name: 'startTime',
                        type: 'i64',
                    },
                    {
                        name: 'endTime',
                        type: 'i64',
                    },
                    {
                        name: 'duration',
                        type: 'i64',
                    },
                    {
                        name: 'claimWindow',
                        type: 'i64',
                    },
                    {
                        name: 'numOfBidders',
                        type: 'u32',
                    },
                    {
                        name: 'active',
                        type: 'bool',
                    },
                ],
            },
        },
        {
            name: 'burnAuction',
            type: {
                kind: 'struct',
                fields: [
                    {
                        name: 'bump',
                        type: 'u8',
                    },
                    {
                        name: 'authority',
                        type: 'publicKey',
                    },
                    {
                        name: 'iteration',
                        type: 'publicKey',
                    },
                    {
                        name: 'bidMap',
                        type: 'publicKey',
                    },
                    {
                        name: 'rewardMint',
                        type: 'publicKey',
                    },
                    {
                        name: 'rewardPoolSize',
                        type: 'u64',
                    },
                    {
                        name: 'toBurnMint',
                        type: 'publicKey',
                    },
                    {
                        name: 'toBurnSize',
                        type: 'u64',
                    },
                    {
                        name: 'startTime',
                        type: 'i64',
                    },
                    {
                        name: 'endTime',
                        type: 'i64',
                    },
                    {
                        name: 'duration',
                        type: 'i64',
                    },
                    {
                        name: 'claimWindow',
                        type: 'i64',
                    },
                    {
                        name: 'numOfBidders',
                        type: 'u32',
                    },
                    {
                        name: 'active',
                        type: 'bool',
                    },
                ],
            },
        },
        {
            name: 'burnAnyAuction',
            type: {
                kind: 'struct',
                fields: [
                    {
                        name: 'bump',
                        type: 'u8',
                    },
                    {
                        name: 'authority',
                        type: 'publicKey',
                    },
                    {
                        name: 'iteration',
                        type: 'publicKey',
                    },
                    {
                        name: 'bidMap',
                        type: 'publicKey',
                    },
                    {
                        name: 'rewardMint',
                        type: 'publicKey',
                    },
                    {
                        name: 'rewardPoolSize',
                        type: 'u64',
                    },
                    {
                        name: 'toBurnCollection',
                        type: 'publicKey',
                    },
                    {
                        name: 'toBurnSize',
                        type: 'u64',
                    },
                    {
                        name: 'startTime',
                        type: 'i64',
                    },
                    {
                        name: 'endTime',
                        type: 'i64',
                    },
                    {
                        name: 'duration',
                        type: 'i64',
                    },
                    {
                        name: 'claimWindow',
                        type: 'i64',
                    },
                    {
                        name: 'numOfBidders',
                        type: 'u32',
                    },
                    {
                        name: 'active',
                        type: 'bool',
                    },
                ],
            },
        },
        {
            name: 'holding',
            type: {
                kind: 'struct',
                fields: [
                    {
                        name: 'owner',
                        type: 'publicKey',
                    },
                    {
                        name: 'auction',
                        type: 'publicKey',
                    },
                    {
                        name: 'source',
                        type: 'publicKey',
                    },
                    {
                        name: 'amount',
                        type: 'u32',
                    },
                    {
                        name: 'claimed',
                        type: 'bool',
                    },
                    {
                        name: 'numOfBids',
                        type: 'u8',
                    },
                    {
                        name: 'lastUpdated',
                        type: 'i64',
                    },
                ],
            },
        },
        {
            name: 'bidMap',
            type: {
                kind: 'struct',
                fields: [
                    {
                        name: 'auction',
                        type: 'publicKey',
                    },
                    {
                        name: 'data',
                        type: {
                            vec: 'u32',
                        },
                    },
                ],
            },
        },
        {
            name: 'bidItem',
            type: {
                kind: 'struct',
                fields: [
                    {
                        name: 'owner',
                        type: 'publicKey',
                    },
                    {
                        name: 'auction',
                        type: 'publicKey',
                    },
                    {
                        name: 'time',
                        type: 'i64',
                    },
                    {
                        name: 'amount',
                        type: 'u32',
                    },
                ],
            },
        },
    ],
    errors: [
        {
            code: 6000,
            name: 'InvalidProgram',
            msg: 'The program ID is incorrect',
        },
        {
            code: 6001,
            name: 'InvalidAuthority',
            msg: 'This account does not have authority to perform this instruction',
        },
        {
            code: 6002,
            name: 'AuctionAlreadyStarted',
            msg: 'This auction has already started',
        },
        {
            code: 6003,
            name: 'AuctionNotStarted',
            msg: 'This auction has not started',
        },
        {
            code: 6004,
            name: 'BidTooLow',
            msg: 'This bid is lower than the minimum required',
        },
        {
            code: 6005,
            name: 'InvalidToken',
            msg: 'You cannot attach this type of token',
        },
        {
            code: 6006,
            name: 'TokenNotOwned',
            msg: 'Requester does not own this token',
        },
        {
            code: 6007,
            name: 'UnrecognizedStar',
            msg: 'The Star account does not match this metadata',
        },
        {
            code: 6008,
            name: 'BidNotUnique',
            msg: 'This bid already exists in the bid map',
        },
        {
            code: 6009,
            name: 'NameTooLong',
            msg: 'The name string is too long',
        },
        {
            code: 6010,
            name: 'ImageTooLong',
            msg: 'The image string is too long',
        },
        {
            code: 6011,
            name: 'InvalidAuction',
            msg: 'This auction is not assigned to the given holding',
        },
        {
            code: 6012,
            name: 'AuctionNotFinished',
            msg: 'This auction has not finished yet',
        },
        {
            code: 6013,
            name: 'AuctionClaimsRemain',
            msg: "This auction's bid map is not empty",
        },
        {
            code: 6014,
            name: 'AuctionHasFinished',
            msg: 'This auction has finished',
        },
        {
            code: 6015,
            name: 'InvalidMint',
            msg: 'The token mint on this metadata account does not match',
        },
        {
            code: 6016,
            name: 'BidNotFound',
            msg: 'The bid was not found in the bid map',
        },
        {
            code: 6017,
            name: 'InvalidOwner',
            msg: 'The owner does not own this enrollment record',
        },
        {
            code: 6018,
            name: 'AlreadyClaimed',
            msg: 'This holding has already been fully claimed',
        },
        {
            code: 6019,
            name: 'BidMapError',
            msg: 'Something went wrong trying to add to the bid map',
        },
        {
            code: 6020,
            name: 'TooManyBids',
            msg: 'The bidder has bid too many times',
        },
        {
            code: 6021,
            name: 'RequiresTraitAndNumber',
            msg: 'If either trait or trait number is set, you must have both',
        },
    ],
};
