import hashTable from '@/helpers/ids';
import {
    RPC_DEVNET,
    RPC_MAINNET,
    CLUSTER,
    DEGENERATE_API_ENDPOINT,
    LOOT_BOX_MINT,
    INBREDS,
    SECONDS_PER_DAY,
    OPAL_ID,
} from '@/helpers/constants';
import {
    Connection,
    PublicKey,
    type ParsedAccountData,
    SYSVAR_CLOCK_PUBKEY,
    SystemProgram,
    TransactionInstruction,
    Transaction,
    ComputeBudgetProgram,
} from '@solana/web3.js';
import { TOKEN_PROGRAM_ID, getAssociatedTokenAddress } from '@solana/spl-token';
import {
    PROGRAM_ID as TOKEN_METADATA_PROGRAM_ID,
    Metadata,
} from '@metaplex-foundation/mpl-token-metadata';
import { sliceIntoChunks } from '@/helpers/utilities';
import { type AnchorWallet } from 'solana-wallets-vue';
import {
    getBookAccount,
    getBookAddress,
    getEnrollmentAccounts,
    getMetadataAddress,
    getPouchAccount,
    getProgramInactive,
    getVoucherAddress,
    type EnrollmentAccountInfo,
    type MetadataAccountInfo,
} from '@/api/degenerate-star';
import {
    getProgram as getHatchProgram,
    getRequestAddress,
} from '@/api/degenerate-hatch';
import { GOLD_STAR_ID, MAX_HATCH_PER_TX } from '@/helpers/constants';
import { AuctionType } from '@/api/degenerate-trait-auctions';

// How many auctions to feature.
export const FEATURED_AUCTIONS = 1;

// Global connection for this module.
export const connection: Connection = new Connection(
    CLUSTER === `mainnet-beta` ? RPC_MAINNET : RPC_DEVNET
);

// Domain
export interface Domain {
    address: string;
    domain: string | undefined;
    favorite: boolean;
    owner: string;
    program: string;
}

// Get Stickers result type.
export type GetCollectiblesResult = {
    goldStar: {
        account: string | null;
        number: number;
    };
    opal: {
        account: string | null;
        number: number;
    };
};

export type GetItemsResult = {
    account: string | null;
    number: number;
};

export type TokensData = {
    id: number;
    mint: string;
    type: string;
    status: number;
    number: number;
    name: string;
    image: string;
    metadata: string;
    thumbnail: string;
    rate: number;
    timeSinceLastDrip: number | null;
    redeemable: number;
    attending: boolean;
};

export type VouchersData = {
    mint: string;
    type: number;
    voucher: string;
    used: boolean;
    redeemable: number;
    thumbnail: string;
};

export type EggsData = {
    id: number;
    address: string;
    mint: string;
    type: string;
    status: number;
    number: number;
    name: string;
    image: string;
    metadata: string;
    thumbnail: string;
    background: string;
    eggColor: string;
    mouth: string;
    nose: string;
    eyes: string;
    skinType: string;
    hatchStatus: number;
};

export type BearsData = {
    id: number;
    mint: string;
    name: string;
    image: string;
    description: string;
    background: string;
    fur: string;
    clothing: string;
    head: string;
    eyes: string;
    mouth: string;
};

export type EggsResponse = {
    bears: BearsData[];
    eggs: EggsData[];
};

// Tokens response type.
export type TokensResponse = {
    tokens: TokensData[];
    vouchers: VouchersData[];
};

export type Manifest = {
    name: string;
    symbol: string;
    description: string;
    seller_fee_basis_points: number;
    image: string;
};

// Mint response type.
export type MintResponse = TokensData & {
    mint: string;
    status: number;
};

export type CombinedMetadataInfo = MetadataAccountInfo &
    TokensResponse &
    MintResponse;

export const priorityFeeIx = () => {
    return ComputeBudgetProgram.setComputeUnitPrice({
        microLamports: 1_000_000,
    });
};

export const getPacksRemaining = async (address: PublicKey) => {
    const result = await connection.getParsedTokenAccountsByOwner(address, {
        programId: TOKEN_PROGRAM_ID,
    });
    return result.value.length;
};

// Signs, sends, and confirms transaction.
export const submitTransaction = async ({
    wallet,
    tx,
}: {
    wallet: typeof AnchorWallet;
    tx: Transaction;
}) => {
    tx.add(priorityFeeIx());
    const { blockhash, lastValidBlockHeight } =
        await connection.getLatestBlockhash();
    tx.feePayer = wallet.publicKey;
    tx.recentBlockhash = blockhash;
    tx.lastValidBlockHeight = lastValidBlockHeight;
    const signedTx = await wallet.signTransaction(tx);
    const signature = await connection.sendRawTransaction(
        signedTx.serialize(),
        {
            skipPreflight: false,
        }
    );
    const result = await connection.confirmTransaction(
        {
            signature,
            blockhash,
            lastValidBlockHeight,
        },
        `confirmed`
    );
    if (result && result.value && result.value.err) {
        throw Error(JSON.stringify(result.value.err));
    }
    return signature;
};

// Function to pull ascend counter.
export const getAscendedCount = async (): Promise<number> => {
    const timeNow = parseInt((new Date().valueOf() / 1000).toString());
    const response = await fetch(
        `${DEGENERATE_API_ENDPOINT}stats/ascended?timestamp=${timeNow}`
    ).then((r) => r.json());
    console.log(response);
    return response.value;
};

export const getDripRateByName = (name: string): number => {
    if (name.startsWith(`Degen Drop Bear`)) {
        const num = name.split(`#`)[1];
        if (INBREDS.includes(Number(num))) {
            return 60;
        } else return 40;
    }
    if (name.startsWith(`Degen Elder Drop Bear`)) return 100;
    if (name.startsWith(`SMB Gen3`)) return 5;
    if (name.startsWith(`Degen Ape HS`)) return 20;
    if (name.startsWith(`Degen Ape`)) return 5;
    if (name.startsWith(`Degenerate Egg Crate`)) return 100;
    if (name.startsWith(`Degen Trash Panda`)) return 1;
    return 0;
};

export const getRedeemableFromDripTime = (
    rate: number,
    time: number
): number => {
    const timeDiff = new Date().valueOf() / 1000 - time;
    return Math.floor((rate / SECONDS_PER_DAY) * timeDiff);
};

export const getDomainsByOwner = async (owners: PublicKey[]) => {
    // const timestamp = parseInt((new Date().valueOf() / 1000).toString());
    const ownerStrs = [...new Set(owners.map((i: PublicKey) => i.toBase58()))];
    const ownersObj: { [key: string]: Domain[] } = {};
    for (const addr of ownerStrs) {
        ownersObj[addr] = [
            {
                address: addr,
                domain: addr,
                favorite: true,
                owner: addr,
                program: addr,
            },
        ];
    }
    return ownersObj;

    // Split into chunks and fetch.
    // const chunks = sliceIntoChunks(ownerStrs, 100);
    // let domains: Domain[] = [];
    // for (const chunk of chunks) {
    //     const result = await fetch(`${OPAL_SNS_API_ENDPOINT}domains`, {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify({
    //             favorite: false,
    //             owners: chunk,
    //             timestamp,
    //         }),
    //     }).then((r) => r.json());
    //     domains = [...domains, ...result.result];
    // }
    // const domainsByOwner: { [key: string]: Domain[] } = {};
    // for (const domain of domains) {
    //     if (domain.owner in domainsByOwner) {
    //         domainsByOwner[domain.owner].push(domain);
    //     } else {
    //         domainsByOwner[domain.owner] = [domain];
    //     }
    // }
    // return domainsByOwner;
};

// Grabs all the user's token accounts with the mint as the key.
export const getTokenAccountsByMint = async (owner: PublicKey) => {
    return await connection
        .getParsedTokenAccountsByOwner(owner, {
            programId: TOKEN_PROGRAM_ID,
        })
        .then((result) =>
            result.value.reduce(
                (obj: any, item) => (
                    (obj[item.account.data.parsed.info.mint] =
                        item.pubkey.toBase58()),
                    obj
                ),
                {}
            )
        );
};

export const getCollectionMintHref = (type: string) => {
    return `${DEGENERATE_API_ENDPOINT}collections?collection=${type}`;
};

export const mutateStars = async (wallet: typeof AnchorWallet) => {
    const timeNow = parseInt((new Date().valueOf() / 1000).toString());
    const { result } = await fetch(
        `${DEGENERATE_API_ENDPOINT}transactions/mutate-stars?timestamp=${timeNow}&wallet=${wallet.publicKey.toBase58()}`
    ).then((r) => r.json());
    const tx = Transaction.from(Buffer.from(result.transaction, `base64`));
    const signedTx = await wallet.signTransaction(tx);
    const signature = await connection.sendRawTransaction(
        signedTx.serialize(),
        {
            skipPreflight: true,
            preflightCommitment: 'confirmed',
        }
    );
    await connection.confirmTransaction(
        {
            signature,
            blockhash: result.blockhash.blockhash,
            lastValidBlockHeight: result.blockhash.lastValidBlockHeight,
        },
        `finalized`
    );
    return signature;
};

export const settleHolding = async (
    wallet: typeof AnchorWallet,
    auction: PublicKey,
    type: AuctionType
) => {
    const timeNow = parseInt((new Date().valueOf() / 1000).toString());
    const typeNum =
        type === AuctionType.Standard ? 0 : AuctionType.Burn ? 1 : 2;
    const { result } = await fetch(
        `${DEGENERATE_API_ENDPOINT}transactions/auction/settle-holding?timestamp=${timeNow}&wallet=${wallet.publicKey.toBase58()}&auction=${auction.toBase58()}&type=${typeNum}`
    ).then((r) => r.json());
    const tx = Transaction.from(Buffer.from(result.transaction, `base64`));
    const signedTx = await wallet.signTransaction(tx);
    const signature = await connection.sendRawTransaction(
        signedTx.serialize(),
        {
            skipPreflight: true,
            preflightCommitment: 'confirmed',
        }
    );
    await connection.confirmTransaction(
        {
            signature,
            blockhash: result.blockhash.blockhash,
            lastValidBlockHeight: result.blockhash.lastValidBlockHeight,
        },
        `confirmed`
    );
    return signature;
};

// Function to fetch raw metadata from a Token Metadata PDA.
export const getMetadata = async (mint: PublicKey): Promise<Metadata> => {
    const seeds = [
        Buffer.from('metadata', 'utf8'),
        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
        mint.toBuffer(),
    ];
    const [metadataPda] = await PublicKey.findProgramAddress(
        seeds,
        TOKEN_METADATA_PROGRAM_ID
    );
    return await Metadata.fromAccountAddress(connection, metadataPda);
};

export const getStarMetadata = async (mint: string) => {
    const program = getProgramInactive();
    const [metadata] = getMetadataAddress({
        token: new PublicKey(mint),
        star: GOLD_STAR_ID,
    });
    const pdaInfo = await program.account.metadata.fetch(
        new PublicKey(metadata)
    );
    const tokenInfo = await getMetadata(new PublicKey(mint));
    const manifestInfo = await fetch(tokenInfo.data.uri).then((r) => r.json());
    return {
        ...pdaInfo,
        authority: pdaInfo.authority.toBase58(),
        token: pdaInfo.token.toBase58(),
        star: pdaInfo.star.toBase58(),
        ...tokenInfo,
        updateAuthority: tokenInfo.updateAuthority.toBase58(),
        ...manifestInfo,
    };
};

export const getVoucher = async (mint: string) => {
    const program = getProgramInactive();
    const [voucher] = getVoucherAddress({
        token: new PublicKey(mint),
        star: GOLD_STAR_ID,
    });
    const pdaInfo = await program.account.voucher.fetch(new PublicKey(voucher));
    const tokenInfo = await getMetadata(new PublicKey(mint));
    const manifestInfo = await fetch(tokenInfo.data.uri).then((r) => r.json());
    return {
        ...pdaInfo,
        token: pdaInfo.token.toBase58(),
        star: pdaInfo.star.toBase58(),
        redeemer: pdaInfo.redeemer.toBase58(),
        amount: pdaInfo.amount.toNumber(),
        ...tokenInfo,
        updateAuthority: tokenInfo.updateAuthority.toBase58(),
        ...manifestInfo,
    };
};

// Function to grab the token's manifest from the third party IPFS.
export const getManifest = async (uri: string) =>
    await fetch(uri).then((r) => r.json());

// Basic function to fetch apes from Solana.
export const getApes = async (publicKey: PublicKey) => {
    const mints = Object.keys(hashTable);
    return await connection
        .getParsedTokenAccountsByOwner(publicKey, {
            programId: TOKEN_PROGRAM_ID,
        })
        .then((response) => response.value)
        .then((tokenAccounts) =>
            tokenAccounts.filter(
                (tokenAccount) =>
                    tokenAccount.account.data.parsed.info.tokenAmount
                        .uiAmount === 1
            )
        )
        .then((tokenAccounts) =>
            tokenAccounts.map(
                (tokenAccount) => tokenAccount.account.data.parsed.info.mint
            )
        )
        .then((tokens) => tokens.filter((token) => mints.includes(token)));
};

// Basic function to fetch Degeniverse tokens and their
// corresponding Gold Star Metadata accounts from Solana.
export const getTokens = async (
    wallet: typeof AnchorWallet
): Promise<TokensData[]> => {
    const timeNow = parseInt((new Date().valueOf() / 1000).toString());
    const { tokens }: TokensResponse = await fetch(
        `${DEGENERATE_API_ENDPOINT}tokens?timestamp=${timeNow}&wallet=${wallet.publicKey.toBase58()}`
    ).then((r) => r.json());
    const enrollmentInfos: { [key: string]: EnrollmentAccountInfo } =
        await getEnrollmentAccounts({
            connection,
            wallet,
            tokens: tokens.map((token) => token.mint),
        }).then((result) =>
            result.reduce(
                (obj: any, item: EnrollmentAccountInfo) => (
                    (obj[item.mint.toBase58()] = item), obj
                ),
                {}
            )
        );
    const result = tokens.map((token) => {
        const dripTime =
            enrollmentInfos[token.mint]?.dripTime.toNumber() || null;
        const dripRate = getDripRateByName(token.name);
        return {
            ...token,
            rate: dripRate,
            timeSinceLastDrip: dripTime ? getDripRateByName(token.name) : null,
            redeemable: dripTime
                ? getRedeemableFromDripTime(dripRate, dripTime)
                : 0,
            attending: dripTime != null ? true : false,
        };
    });
    console.log(`Fetched user tokens: `, result);
    return result;
};

export const requestHatch = async (
    wallet: string,
    signature: string,
    token: string
) => {
    const timeNow = parseInt((new Date().valueOf() / 1000).toString());
    return await fetch(`${DEGENERATE_API_ENDPOINT}hatch?timestamp=${timeNow}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            wallet,
            signature,
            token,
        }),
    }).then((r) => r.json());
};

export const createRequestInstruction = async (
    wallet: typeof AnchorWallet,
    mint: string
) => {
    const program = getHatchProgram({ connection, wallet });
    const tokenAccount = await getAssociatedTokenAddress(
        new PublicKey(mint),
        wallet.publicKey
    );
    const requestAccount = getRequestAddress({ mint: new PublicKey(mint) });
    return await program.methods
        .createRequest(false)
        .accounts({
            owner: wallet.publicKey,
            token: mint,
            tokenAccount,
            request: requestAccount,
            clock: SYSVAR_CLOCK_PUBKEY,
            systemProgram: SystemProgram.programId,
        })
        .instruction();
};

export const addToList = async (
    wallet: typeof AnchorWallet
): Promise<boolean> => {
    const timeNow = parseInt((new Date().valueOf() / 1000).toString());
    return await fetch(
        `${DEGENERATE_API_ENDPOINT}shakening?timestamp=${timeNow}&wallet=${wallet.publicKey.toBase58()}`
    ).then((r) => r.json());
};

// Basic function to fetch Degeniverse tokens and their
// corresponding Gold Star Metadata accounts from Solana.
export const getEggs = async (wallet: PublicKey): Promise<EggsResponse> => {
    const timeNow = parseInt((new Date().valueOf() / 1000).toString());
    const endpoint = CLUSTER === `mainnet-beta` ? `eggs/all` : `eggs/devnet`;
    return await fetch(
        `${DEGENERATE_API_ENDPOINT}${endpoint}?timestamp=${timeNow}&wallet=${wallet.toBase58()}`
    ).then((r) => r.json());
};

export const getTraits = async (type: string): Promise<any[]> => {
    const timeNow = parseInt((new Date().valueOf() / 1000).toString());
    return await fetch(
        `${DEGENERATE_API_ENDPOINT}traits?timestamp=${timeNow}&type=${type}`
    )
        .then((r) => r.json())
        .then((r) => r.result);
};

export const getStickers = async (
    wallet: typeof AnchorWallet
): Promise<GetCollectiblesResult> => {
    const [stickerBook]: [PublicKey, number] = getBookAddress({
        owner: wallet.publicKey,
        star: GOLD_STAR_ID,
    });
    const [opalPouch]: [PublicKey, number] = getBookAddress({
        owner: wallet.publicKey,
        star: OPAL_ID,
    });
    const goldStarAccountInfo = await getBookAccount({ connection, wallet });
    const opalAccountInfo = await getPouchAccount({ connection, wallet });

    let goldStarInfo: any;
    if (!goldStarAccountInfo) {
        goldStarInfo = { account: null, number: 0 };
    } else {
        goldStarInfo = {
            account: stickerBook.toBase58(),
            number: goldStarAccountInfo.amount.toNumber(),
        };
    }

    let opalInfo: any;
    if (!opalAccountInfo) {
        opalInfo = { account: null, number: 0 };
    } else {
        opalInfo = {
            account: opalPouch.toBase58(),
            number: opalAccountInfo.amount.toNumber(),
        };
    }

    return {
        goldStar: goldStarInfo,
        opal: opalInfo,
    };
};

export const getBoxes = async (
    wallet: typeof AnchorWallet
): Promise<GetItemsResult> => {
    const tokenAccount: PublicKey = await getAssociatedTokenAddress(
        LOOT_BOX_MINT,
        wallet.publicKey
    );
    const accountInfo = await connection.getParsedAccountInfo(tokenAccount);
    if (!accountInfo.value) {
        return { account: null, number: 0 };
    } else {
        return {
            account: tokenAccount.toBase58(),
            number: (accountInfo.value?.data as ParsedAccountData).parsed.info
                .tokenAmount.uiAmount,
        };
    }
};

// Function to fetch a user's available lamports.
export const getUserLamports = async (
    publicKey: PublicKey
): Promise<number> => {
    return await connection
        .getAccountInfo(publicKey)
        .then((response) => response!.lamports);
};

// We can send up to 7 claims per transaction.
export const hatchAll = async (
    wallet: typeof AnchorWallet,
    mints: string[]
) => {
    // Setup the program.
    const program = getHatchProgram({ connection, wallet });

    // Get all the user's token accounts.
    const tokenAccounts = await getTokenAccountsByMint(wallet.publicKey);

    // Gather all the instructions.
    const instructions: TransactionInstruction[] = [];
    for (const mint of mints) {
        const ix = await program.methods
            .createRequest(true)
            .accounts({
                owner: wallet.publicKey,
                token: new PublicKey(mint),
                tokenAccount: tokenAccounts[mint],
                request: getRequestAddress({ mint: new PublicKey(mint) }),
                clock: SYSVAR_CLOCK_PUBKEY,
                systemProgram: SystemProgram.programId,
            })
            .instruction();
        instructions.push(ix);
    }

    // Now, split the instructions into chunks.
    const chunkedInstructions = sliceIntoChunks(instructions, MAX_HATCH_PER_TX);

    // Get recent blockhash.
    const { blockhash } = await connection.getLatestBlockhash();

    // Now turn each chunk into a transaction.
    const transactions = chunkedInstructions.map((chunk) => {
        const transaction = new Transaction().add(...chunk);
        transaction.feePayer = wallet.publicKey;
        transaction.recentBlockhash = blockhash;
        return transaction;
    });

    // Sign all transactions.
    const signedTransactions = await wallet.signAllTransactions(transactions);

    // Check if the transaction array is empty.
    if (!signedTransactions || !(signedTransactions.length > 0)) {
        throw Error(`Signature Array is empty!`);
    }

    // Broadcast raw transaction.
    const signatures: string[] = [];
    for (const tx of signedTransactions) {
        signatures.push(
            await connection.sendRawTransaction(tx.serialize(), {
                skipPreflight: false,
                preflightCommitment: 'confirmed',
            })
        );
    }

    // Await process.
    const confirmations = [];
    for (const signature of signatures) {
        confirmations.push(
            connection.confirmTransaction(signature, 'confirmed')
        );
    }
    try {
        return await Promise.all(confirmations);
    } catch (e) {
        throw Error(`One or more transactions did not confirm.`);
    }
};

export const getNumHatched = async () => {
    const timeNow = parseInt((new Date().valueOf() / 1000).toString());
    return await fetch(
        `${DEGENERATE_API_ENDPOINT}stats/hatched?timestamp=${timeNow}`
    )
        .then((r) => r.json())
        .then((r) => r.value);
};
