import { Connection, PublicKey } from '@solana/web3.js';
import * as anchor from '@coral-xyz/anchor';
import type { AnchorWallet } from 'solana-wallets-vue';
import { PROGRAM_ID as METADATA_PROGRAM_ID } from '@metaplex-foundation/mpl-token-metadata';

/*
    Constants
*/

export const REQUEST_SEED: string = `DEGENERATEHATCHREQUEST`;
export const PROGRAM_ID: PublicKey = new PublicKey(
    `HatchZDye9XDsmq1RULSZy5djDAE3jmfreNDFydWMeVj`
);

/*
    Types
*/

export interface GetProgramArgs {
    connection: Connection;
    wallet: typeof AnchorWallet;
}

export interface GetRequestAddressArgs {
    mint: PublicKey;
}

export interface GetMetadataAddressArgs {
    mint: string;
}

/*
    Program Functions
*/

export const getProgram = ({
    connection,
    wallet,
}: GetProgramArgs): anchor.Program<DegenerateHatch> => {
    const provider = new anchor.AnchorProvider(
        connection,
        wallet,
        anchor.AnchorProvider.defaultOptions()
    );
    return new anchor.Program(IDL, PROGRAM_ID, provider);
};

export const getMetadataAddress = ({
    mint,
}: GetMetadataAddressArgs): anchor.web3.PublicKey => {
    return anchor.web3.PublicKey.findProgramAddressSync(
        [
            Buffer.from('metadata'),
            METADATA_PROGRAM_ID.toBuffer(),
            new PublicKey(mint).toBuffer(),
        ],
        METADATA_PROGRAM_ID
    )[0];
};

export const getRequestAddress = ({
    mint,
}: GetRequestAddressArgs): PublicKey => {
    return PublicKey.findProgramAddressSync(
        [Buffer.from(REQUEST_SEED), mint.toBuffer()],
        PROGRAM_ID
    )[0];
};

export type DegenerateHatch = {
    version: '0.1.0';
    name: 'degenerate_hatch';
    instructions: [
        {
            name: 'createRequest';
            accounts: [
                {
                    name: 'owner';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'token';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'tokenAccount';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'request';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'clock';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'systemProgram';
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [
                {
                    name: 'batch';
                    type: 'bool';
                },
            ];
        },
        {
            name: 'fulfillRequest';
            accounts: [
                {
                    name: 'admin';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'request';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'clock';
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [];
        },
        {
            name: 'destroyRequest';
            accounts: [
                {
                    name: 'admin';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'request';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'clock';
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [];
        },
    ];
    accounts: [
        {
            name: 'request';
            type: {
                kind: 'struct';
                fields: [
                    {
                        name: 'owner';
                        type: 'publicKey';
                    },
                    {
                        name: 'token';
                        type: 'publicKey';
                    },
                    {
                        name: 'creationTime';
                        type: 'i64';
                    },
                    {
                        name: 'fulfillmentTime';
                        type: 'i64';
                    },
                    {
                        name: 'fulfilled';
                        type: 'bool';
                    },
                    {
                        name: 'batch';
                        type: 'bool';
                    },
                ];
            };
        },
    ];
    errors: [
        {
            code: 6000;
            name: 'InvalidProgram';
            msg: 'The program ID is incorrect';
        },
        {
            code: 6001;
            name: 'InvalidAuthority';
            msg: 'This account does not have authority to perform this instruction';
        },
        {
            code: 6002;
            name: 'TokenNotOwned';
            msg: 'This user does not own this token';
        },
        {
            code: 6003;
            name: 'InvalidToken';
            msg: 'The token on the request does not match';
        },
        {
            code: 6004;
            name: 'RequestAlreadyFulFilled';
            msg: 'This request has already been fulfilled';
        },
    ];
};

export const IDL: DegenerateHatch = {
    version: '0.1.0',
    name: 'degenerate_hatch',
    instructions: [
        {
            name: 'createRequest',
            accounts: [
                {
                    name: 'owner',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'token',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'tokenAccount',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'request',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [
                {
                    name: 'batch',
                    type: 'bool',
                },
            ],
        },
        {
            name: 'fulfillRequest',
            accounts: [
                {
                    name: 'admin',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'request',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [],
        },
        {
            name: 'destroyRequest',
            accounts: [
                {
                    name: 'admin',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'request',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [],
        },
    ],
    accounts: [
        {
            name: 'request',
            type: {
                kind: 'struct',
                fields: [
                    {
                        name: 'owner',
                        type: 'publicKey',
                    },
                    {
                        name: 'token',
                        type: 'publicKey',
                    },
                    {
                        name: 'creationTime',
                        type: 'i64',
                    },
                    {
                        name: 'fulfillmentTime',
                        type: 'i64',
                    },
                    {
                        name: 'fulfilled',
                        type: 'bool',
                    },
                    {
                        name: 'batch',
                        type: 'bool',
                    },
                ],
            },
        },
    ],
    errors: [
        {
            code: 6000,
            name: 'InvalidProgram',
            msg: 'The program ID is incorrect',
        },
        {
            code: 6001,
            name: 'InvalidAuthority',
            msg: 'This account does not have authority to perform this instruction',
        },
        {
            code: 6002,
            name: 'TokenNotOwned',
            msg: 'This user does not own this token',
        },
        {
            code: 6003,
            name: 'InvalidToken',
            msg: 'The token on the request does not match',
        },
        {
            code: 6004,
            name: 'RequestAlreadyFulFilled',
            msg: 'This request has already been fulfilled',
        },
    ],
};
