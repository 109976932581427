import { PublicKey, type Cluster } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';

export const EXCHG_ART_API_URI: string = `https://api.exchange.art/v2/`;
export const CLUSTER: Cluster = `mainnet-beta`;

// The endpoint to fetch the HMAC Code and timestamp.
export const EXCHG_ART_AUTHORIZATION_ENDPOINT: string = `https://api.exchange.art/v2/utility/timestamp`;

// The API
export const DEGENERATE_API_ENDPOINT: string = `https://api-zzoyp.ondigitalocean.app/`; //`https://api.degenape.academy/`;
export const DEGENERATE_WS_ENDPOINT: string = `wss://api-zzoyp.ondigitalocean.app/`;
export const OPAL_SNS_API_ENDPOINT: string = `https://sns-api.opal.industries/`;

// RPC URIs
export const METAPLEX_RPC: string = `https://api.metaplex.solana.com/`;
export const RPC_MAINNET: string = `https://mainnet.helius-rpc.com/?api-key=ad20960b-6b42-4eeb-b98b-b601dcbd00b6`;
export const RPC_DEVNET: string = `https://devnet.helius-rpc.com/?api-key=ad20960b-6b42-4eeb-b98b-b601dcbd00b6`;
export const RPC_WSS_MAINNET: string = `wss://mainnet.helius-rpc.com/?api-key=ad20960b-6b42-4eeb-b98b-b601dcbd00b6`;

export const BOOTS_API_KEY_MAINNET: string = `V1mMCgj6KCadQjZiLgLce4zBpz4gY3lnaOykM9aZ`;
export const BOOTS_API_KEY_DEVNET: string = `hMnmK4DVtM6QRLcVjlx8c7I555fDWYEW1fnNs4fl`;

export const PACK_ITEM_CLASS: string = `EPTXf77uxtRwFL94crU3hn8DgmyvDW95UKXRQoujFEFb`;
export const BUILD_SECRET: string | undefined = import.meta.env
    .VITE_BUILD_SECRET;

export const ENV = {
    NETWORK: WalletAdapterNetwork.Mainnet,
    STRING: 'mainnet-beta',
    RPC: RPC_MAINNET,
    HELIUS: RPC_MAINNET,
    BOOTS: BOOTS_API_KEY_MAINNET,
};

export const MEMO_PROGRAM_ID = new PublicKey(
    `MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr`
);

// Our initializer for auctions
export const DEGEN_ADMIN: PublicKey = new PublicKey(
    `Degen9nCBXi84Mw88qAW4YWLZ1azni4AcvDcax1LgTxH`
);
export const DEGEN_INITIALIZER: string = `daaLrDfvcT4joui5axwR2gCkGAroruJFzyVsacU926g`;

// Gold Star Program stuff
export const GOLD_STAR_ID: PublicKey = new PublicKey(
    `2Tw3imU5SJLGuATJoFENz72FNvWPYfUkXccSWdkC6nsK`
);
export const OPAL_ID: PublicKey = new PublicKey(
    `2SbDfHkqAMvNFDdYUqzMJYtEb917qM2VDZZNKUogU4xy`
);
export const LOOT_BOX_MINT: PublicKey = new PublicKey(
    `Box8VpZLXvTvvkXhCHEB4hJzAfiP4r8NPJ9neha4ZT9B`
);
export const STORE_ID: PublicKey = new PublicKey(
    `4GCxPeZztT5MKzNcNoVUKUYzwGRpUM2XKk5C9W3MniBJ`
);
export const BOX_STASH: PublicKey = new PublicKey(
    `D7qSv9fkEghuKxc4HycxdJJEyab9eMTaEo9RJWJLFg5Q`
);

export const MAX_COMPUTE_UNITS: number = 1_400_000;
export const MAX_CLAIMS_PER_TX: number = 3;
export const MAX_TOKENS_PER_PAGE: number = 50;
export const STARS_PER_CRATE: number = 169;
export const MAX_HATCH_PER_TX: number = 30;

export const PRIORITY_FEE: number = 1000;

export const ACTIVE_TYPES: string[] = [
    'ape',
    'ape2',
    'panda',
    'ipanda',
    'bear',
    'crate',
    'monke',
    'elder',
];

export const VERIFY_MESSAGE = 'z7KNEFNw2It2MALojtkrKd1q36zEtFNX';

export const SECONDS_PER_DAY: number = 60 * 60 * 24;
export const INBREDS: number[] = [
    2167, 2165, 2169, 2163, 2166, 2162, 2164, 2158, 2153, 2161, 2159, 2155,
    2151, 2154, 1978, 1970, 1989, 1975, 1969, 1987, 1982, 1971, 1976, 1967,
    1963, 1979, 1983, 1993, 1973, 1990, 1985, 1980, 1974, 1984, 1988, 1964,
    1977, 1972, 1994, 1991, 1981, 1965, 1992, 2017, 2042, 2045, 2044, 2004,
    2034, 2018, 2011, 1995, 2006, 2039, 2016, 2024, 1997, 2041, 2036, 2023,
    2013, 2043, 2014, 2019, 2012, 2032, 2000, 2037, 2022, 2038, 2035, 2010,
    2015, 2031, 2021, 2001, 2025, 2009, 1999, 1998, 2030, 2028, 2040, 2078,
    2055, 2070, 2086, 2084, 2056, 2082, 2052, 2080, 2087, 2073, 2048, 2059,
    2071, 2060, 2079, 2077, 2047, 2049, 2068, 2050, 2063, 2051, 2066, 2089,
    2072, 2065, 2046, 2058, 2062, 2076, 2090, 2088, 2081, 2075, 2064, 2074,
    2110, 2109, 2133, 2108, 2091, 2147, 2118, 2125, 2145, 2131, 2097, 2121,
    2142, 2122, 2093, 2099, 2144, 2136, 2101, 2146, 2100, 2124, 2126, 2148,
    2149, 2112, 2132, 2113, 2114, 2102, 2134, 2115, 2111, 2103, 2123, 2106,
    2116, 2098, 2150, 2119, 2120, 2127, 2130, 2143, 2137, 2135, 2107, 2138,
    2141, 2129, 2095, 2104, 2105, 2094, 2172, 2173, 2171, 1885, 1892, 1841,
    1888, 1864, 1863, 1848, 1901, 1884, 1862, 1894, 1843, 1891, 1880, 1873,
    1890, 1865, 1852, 1882, 1845, 1849, 1899, 1868, 1859, 1902, 1853, 1875,
    1904, 1887, 1847, 1858, 1857, 1881, 1903, 1895, 1871, 1872, 1886, 1874,
    1898, 1869, 1878, 1896, 1893, 1861, 1889, 1876, 1850, 1846, 1842, 1851,
    1844, 1897, 1856, 1946, 1932, 1907, 1905, 1919, 1959, 1926, 1960, 1910,
    1929, 1917, 1961, 1947, 1950, 1928, 1941, 1949, 1954, 1918, 1925, 1921,
    1938, 1931, 1944, 1948, 1923, 1920, 1913, 1914, 1906, 1962, 1911, 1955,
    1915, 1956, 1930, 1953, 1943, 1927, 1939, 1909, 1952, 1936, 1916, 1940,
    1935, 1922, 1912, 1951, 1945,
];
