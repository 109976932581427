import bs58 from 'bs58';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addDefaultLocale(en);

// Create formatter (English).
const timeAgo = new TimeAgo('en-US');

export const generateMessage = (wallet: string) => {
    return Buffer.from(bs58.decode(wallet)).toString('hex');
};

export const epochToWen = (time: number, showTimestamp: boolean = true) => {
    const timeSince = timeAgo.format(time, 'mini');
    if (showTimestamp) {
        const timeStr = new Date(time).toUTCString();
        return `${timeSince} (${timeStr})`.replace(`GMT`, `UTC`);
    } else return timeSince;
};

// To LE bytes.
export const toLeBytes = (num: number) => {
    const arr = new Uint8Array(1);
    arr[0] = num;
    return arr;
};

// Basic go-to function for URL re-directing.
export const goTo = (url: string) => window.open(url, '_blank');

export const goToAddress = (address: string) =>
    window.open(`https://solscan.io/account/${address}`, `_blank`);

export const randomRotate = () => Math.floor(Math.random() * 100) - 60;

export const scrollToTop = () => {
    const hrrContainer = document.getElementById(`main`);
    hrrContainer!.scrollTo({ top: 0, behavior: 'smooth' });
};

export const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

// Function to tweet.
export const tweet = (placeholder: string) =>
    goTo(
        `https://twitter.com/intent/tweet?text=${encodeURIComponent(
            placeholder
        )}`
    );

export const shortenAddress = (
    address: string,
    edgeCharacters: number
): string =>
    address.slice(0, edgeCharacters) + '..' + address.slice(-edgeCharacters);

export const getRandomLoadingPhrase = (): string => {
    const phraseList = [
        "I'm not sure what I'm doing here, really.",
        'Looking under your bed. Gross.',
        'Getting permission from your mom.',
        "I'm in ur wallet, checking ur NFTs.",
        'Wow, your wallet is filthy.',
        "I'm glad we could share this moment.",
        "Who's that behind you?",
        'From how you look I thought your wallet would be dirtier.',
        "Don't look at me like that. I'm going as fast as I can.",
        'Trapped in blockchain. Send help.',
        'When I was a kid I wanted to be an astronaut. Now look at me, stuck checking NFTs.',
    ];
    return phraseList[randomIntFromInterval(0, phraseList.length - 1)];
};

export const sillyWord = (): string => {
    const wordList = [
        'herp',
        'derp',
        'mlerp',
        'flerp',
        'gerp',
        'yerp',
        'srerp',
        'knvlerp',
        'kekerp',
        'berp',
        'sherp',
    ];
    return wordList[randomIntFromInterval(0, wordList.length - 1)];
};

export const sexualWord = (): string => {
    const wordList = [
        'uh',
        'uhhh',
        'buh',
        'ungh',
        'oo',
        'mmm',
        'bb',
        'yesss',
        'omg',
        'harder',
        'HARDER',
        'pls',
        'faster',
        'keep going',
    ];
    return wordList[randomIntFromInterval(0, wordList.length - 1)];
};

export const angryCopy = (timesCopied: number): string => {
    if (timesCopied < 10) return `Copied!`;
    else if (timesCopied < 20) return `Copied?`;
    else if (timesCopied < 30) return `Copied...`;
    else if (timesCopied < 40) return `Can you stop?`;
    else if (timesCopied < 50) return `Please?`;
    else if (timesCopied < 60) return `FU!!`;
    else if (timesCopied < 65) return `F U`;
    else if (timesCopied < 70) return `F UUUU`;
    else if (timesCopied < 73) return `SRS?!?!`;
    else if (timesCopied < 78) return `GTFO!!!!`;
    else if (timesCopied < 85) return `...`;
    else if (timesCopied < 100) return sillyWord();
    else if (timesCopied < 105) return `alright`;
    else if (timesCopied < 110) return `have it ur way`;
    else if (timesCopied < 135) return sexualWord();
    else if (timesCopied < 140) return `omg`;
    else if (timesCopied < 145) return `OMG`;
    else if (timesCopied < 150) return `YESS`;
    else if (timesCopied < 155) return `AAA`;
    else if (timesCopied < 160) return `AAAAAA`;
    else if (timesCopied < 165)
        return `AAAAÀÁÂÄÆÃAÀÁÂÄÆÃAÀÁÂÄÆÃAÀÁÂÄÆÃAÀÁÂÄÆÃAÀÁÂÄÆÃAÀÁÂÄÆÃAÀÁÂÄÆÃAÀÁÂÄÆÃAÀÁÂÄÆÃAÀÁÂÄÆÃAÀÁÂÄÆÃ`;
    else if (timesCopied < 170)
        return `██████████████████████████████████████████████████████████████████████████████████████████████████████████████████████████████`;
    else return `???`;
};

export const buildTweet = (tokenName: string) => {
    tweet(
        `Check out this auction of ${tokenName} by @DegenApeAcademy! ${window.location.href}`
    );
};

// Grab the number of decimals in a number.
export const decimals = (number: number): number => {
    return number.toString().includes('.')
        ? number.toString().split('.')[1].length
        : 0;
};

export const randomIntFromInterval = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};

export const sliceIntoChunks = (arr: any[], chunkSize: number) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
};

export const makeId = (length: number): string => {
    let result = '';
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
};

// Function to properly handle timestamp and convert
// it to a readable human format.
export const convertTimestampToReadable = (timestamp: number): string => {
    // Grab all the intervals.
    const seconds = Math.floor(timestamp % 60);
    const minutes = Math.floor((timestamp / 60) % 60);
    const hours = Math.floor((timestamp / 60 / 60) % 24);
    const days = Math.floor(timestamp / 60 / 60 / 24);

    // Prep the intervals for string
    const secondsStr = `${seconds}`.padStart(2, '0') + `s`;
    const minutesStr =
        minutes == 0 && hours == 0 && days == 0
            ? ``
            : `${minutes}`.padStart(2, '0') + `m `;
    const hoursStr =
        hours == 0 && days == 0 ? `` : `${hours}`.padStart(2, '0') + `h `;
    const daysStr = days == 0 ? `` : `${days}d `;

    // Return dat sheit.
    return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`;
};

export const isMobile = (): boolean => {
    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        )
    ) {
        return true;
    } else {
        return false;
    }
};

export const isMobileComprehensive = (): boolean => {
    let check = false;
    (function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
                a.substr(0, 4)
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor);
    return check;
};

// Used for Rewards section on Homeroom.
export const emitMessage = (msg: string) =>
    (document.getElementById(`loader-msg`)!.innerText = msg);
