import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import router from './router';
import Glitch from 'vue-glitch';

import { inject } from '@vercel/analytics';

import SolanaWallets from 'solana-wallets-vue';
import Particles from '@tsparticles/vue3';
import { loadFull } from 'tsparticles';

import { LedgerWalletAdapter } from '@solana/wallet-adapter-wallets';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    faAtom,
    faArrowsToDot,
    faArrowUpRightFromSquare,
    faPeopleArrowsLeftRight,
    faPooStorm,
    faPoo,
    faClone,
} from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faDiscord } from '@fortawesome/free-brands-svg-icons';

import 'solana-wallets-vue/styles.css';
import * as buffer from 'buffer';

// Setup Buffer.
const { Buffer } = buffer;
if (!window.Buffer) window.Buffer = Buffer;

const walletOptions = {
    wallets: [new LedgerWalletAdapter()],
    autoConnect: true,
};

library.add(
    faTwitter,
    faDiscord,
    faAtom,
    faArrowsToDot,
    faArrowUpRightFromSquare,
    faPeopleArrowsLeftRight,
    faPooStorm,
    faPoo,
    faClone
);

const app = createApp(App);

inject();

app.use(createPinia());
app.use(router);
app.use(SolanaWallets, walletOptions);
app.use(Particles, {
    init: async (engine) => {
        await loadFull(engine);
    },
});
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('GlitchComponent', Glitch);
app.mount('#app');
